import styled from "styled-components";

export const ListContainer = styled.div`
    
`;

export const ListTitle = styled.ul`
    
`;

export const List = styled.ul`
    
`;

export const ListItem = styled.li`
    
`;